import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToolbarModule } from 'primeng/toolbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { SkeletonModule } from 'primeng/skeleton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PanelModule } from 'primeng/panel';
import { DataViewModule } from 'primeng/dataview';
import { BadgeModule } from 'primeng/badge';
import { RippleModule } from 'primeng/ripple';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { StepsModule } from 'primeng/steps';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule } from 'primeng/accordion';
import { SplitterModule } from 'primeng/splitter';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { CarouselModule } from 'primeng/carousel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import { TimelineModule } from 'primeng/timeline';
import { NgbCarouselModule, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from '../layout/footer/footer.component';
import { NavbarComponent } from '../layout/navbar/navbar.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin
]);



@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    //Primeng
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    InputTextModule,
    TabViewModule,
    TableModule,
    ToastModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ButtonModule,
    ProgressBarModule,
    ToolbarModule,
    RadioButtonModule,
    InputTextareaModule,
    TooltipModule,
    InputNumberModule,
    SkeletonModule,
    DataViewModule,
    PanelModule,
    BadgeModule,
    SelectButtonModule,
    RippleModule,
    CardModule,
    MessagesModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    StepsModule,
    AccordionModule,
    SplitterModule,
    FieldsetModule,
    FileUploadModule,
    CarouselModule,
    ToggleButtonModule,
    AutoCompleteModule,
    PaginatorModule,
    TimelineModule,
    //Ng plug-in
    NgbDatepickerModule,
    NgSelectModule,
    NgbCarouselModule,
    NgOptionHighlightModule,
    NgbModule,
    FullCalendarModule,
    ReactiveFormsModule,



  ],
  exports: [
    //Primeng
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    InputTextModule,
    TabViewModule,
    TableModule,
    ToastModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ButtonModule,
    ProgressBarModule,
    ToolbarModule,
    RadioButtonModule,
    InputTextareaModule,
    TooltipModule,
    InputNumberModule,
    SkeletonModule,
    DataViewModule,
    PanelModule,
    BadgeModule,
    SelectButtonModule,
    RippleModule,
    CardModule,
    MessagesModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    StepsModule,
    AccordionModule,
    SplitterModule,
    FieldsetModule,
    FileUploadModule,
    CarouselModule,
    ToggleButtonModule,
    AutoCompleteModule,
    PaginatorModule,
    TimelineModule,
    NgbDatepickerModule,
    NgSelectModule,
    NgbCarouselModule,
    NgOptionHighlightModule,
    NgbModule,

    FullCalendarModule,
    ReactiveFormsModule,
    FooterComponent,
    NavbarComponent,


  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
