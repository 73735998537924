import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import Pusher from 'pusher-js';
@Injectable({
  providedIn: "root",
})
export class LoginService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  private subject: Subject<String> = new Subject<String>();
  private pusherClient: Pusher;
  session = this.cookie.get('session_id');
  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Cookie': 'frontend_lang=en_US; session_id=' + this.session
    })
    // withCredentials:true,
    
  };
  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private router: Router
  ) { }
  login(result) {
    return this.http.post(`${environment.url}/api/auth/login/`, result, this.httpOptions).pipe(
      first(), tap(() => {
        this.pusherClient = new Pusher('21e0357009e985396975', { cluster: 'ap2' });
        const channel = this.pusherClient.subscribe('my-channel');
        channel.bind(
          'my-event',
          (data: any) => {
            // this.subject.next(new Feed(data.title, data.body, new Date(data.time)));
          }
        );
        // const IsCookieExists: boolean = this.cookie.check('session_id');
        // if(IsCookieExists){
        //   this.isAuthenticated$=true;
        // }else{
        //   this.isAuthenticated$=false;
        // }
      }
      )
    );
  }
  loggedUser(user) {
    return this.http.post(`${environment.url}/api/user`, user, this.httpOptions);
  }
  getFeedItems(): Observable<String> {
    return this.subject.asObservable();
  }
  verifytoken(result1) {
    return this.http.post(`${environment.url}/api/employees`, result1, this.httpOptions);
  }
  approveAuthority(result2) {
    return this.http.post(`${environment.url}/api/approve_authority/`, result2, this.httpOptions);
  }
  maintanenceTime(result2) {
    return this.http.post(`${environment.url}/api/maintanance_time/`, result2, this.httpOptions);
  }
  family_info(result2) {
    return this.http.post(`${environment.url}/api/family_information/`, result2, this.httpOptions);
  }
  // skill_sets(result2){
  //   return this.http.post(`${this.url}api/employee_skill_sets/`,result2,this.httpOptions);
  // }
  logout(logout) {
    return this.http.post(`${environment.url}/api/auth/logout`, logout, this.httpOptions);
  }
  show() {
    this._loading.next(true);
  }
  hide() {
    this._loading.next(false);
  }
  microsoftUrl(res) {
    return this.http.post(`${environment.url}/api/microsoft/redirect`, res, this.httpOptions);
  }
  rewardsemp(res) {
    return this.http.post(`${environment.url}/api/employee_awards`, res, this.httpOptions);
  }
  emp_skil_set(res) {
    return this.http.post(`${environment.url}/api/employee_skill_sets`, res,this.httpOptions);
  }
  Save_emp_skil(res) {
    return this.http.post(`${environment.url}/api/save_skills_employee`, res, this.httpOptions);
  }
  delete_skill(res) {
    return this.http.post(`${environment.url}/api/delete_skills_employee`, res, this.httpOptions);
  }
  storePolicyAcceptance(accept: boolean) {
    if(accept){
    localStorage.setItem('policyAccept', accept.toString());
    }
  }
  getPolicyAcceptance(): boolean {
    const policyAccept = localStorage.getItem('policyAccept');
    return policyAccept === 'true';
  }
}
