import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";



@Injectable({
  providedIn: "root",
})

export class OndutyService {
 
  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8',
    })
    
  };

  private messageSource = new BehaviorSubject('');
  currentcount = this.messageSource.asObservable();
constructor(
    private http: HttpClient,
 
    private router: Router
  ) {}

  ondutyCount(count: any) {
  this.messageSource.next(count)
  }
  

  onduty_list(result2){
    return this.http.post(`${environment.url}/api/onduty_list`,result2,this.httpOptions);
  }
  onduty_approve(result2){
    return this.http.post(`${environment.url}/api/approve_onduty_list`,result2,this.httpOptions);
  }
  onduty_apply(result2){
    return this.http.post(`${environment.url}/api/onduty_apply`,result2,this.httpOptions);
  }
  onduty_delete(onduty_del){
    return this.http.post(`${environment.url}/api/delete_list`,onduty_del,this.httpOptions);
  }
  onduty_action(onduty){
    return this.http.post(`${environment.url}/api/onduty_action`,onduty,this.httpOptions);
  }
  
}