import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  heading = "";
  constructor(public router: Router) {

  }

  ngOnInit(): void {

  }
  check() {

    if (this.router.url === '/profile/profile') {
      this.heading = "My Profile";
    }
    else if (this.router.url === '/attendance/attendance') {
      this.heading = "Attendance";
    }
    else if (this.router.url === '/attendance/dailyattedance') {
      this.heading = "Daily Attendance";
    }
    else if (this.router.url === '/leave/leaveapply') {
      this.heading = "Leave Apply";
    }
    else if (this.router.url === '/wfh/wfhapply') {
      this.heading = " Work From Home Apply";
    }
    else if (this.router.url === '/permission/permissionapply') {
      this.heading = "Permission Apply";
    }
    else if (this.router.url === '/compoff/compoffapply') {
      this.heading = "Compoff Apply";
    }
    else if (this.router.url === '/onduty/ondutyapply') {
      this.heading = "OnDuty Apply";
    }
    else if (this.router.url === '/resignation/resignationapply') {
      this.heading = "Resignation Apply";
    }
    else if (this.router.url === '/leave/leavelist') {
      this.heading = "Leave List";
    }
    else if (this.router.url === '/wfh/wfhlist') {
      this.heading = "Work From Home List";
    }
    else if (this.router.url === '/permission/permissionlist') {
      this.heading = "Permission List";
    }
    else if (this.router.url === '/compoff/compofflist') {
      this.heading = "Compoff List";
    }
    else if (this.router.url === '/onduty/ondutylist') {
      this.heading = "Onduty List";
    }
    else if (this.router.url === '/leave/leaveapprovelist') {
      this.heading = "Leave Approval";
    }
    else if (this.router.url === '/wfh/wfhapprovelist') {
      this.heading = "Work From Home Approval ";
    }
    else if (this.router.url === '/permission/permapprovelist') {
      this.heading = "Permission Approval ";
    }
    else if (this.router.url === '/compoff/compoffapprovelist') {
      this.heading = "Compoff Approval";
    }
    else if (this.router.url === '/onduty/ondutyapprovelist') {
      this.heading = "Onduty Approval";
    }
    else if (this.router.url === '/resignation/resignationapprove') {
      this.heading = "Resignation Approval";
    }
    else if (this.router.url === '/resignation/noDueApprovalList') {
      this.heading = "No Due Approval";
    }
    else if (this.router.url === '/resignation/resignationlistview') {
      this.heading = "Resignation Approval Status";
    }
    else if (this.router.url === '/support/supportcontact') {
      this.heading = "Support Team contact";
    }
    else if (this.router.url === '/holidaylist/holidaylist') {
      this.heading = "Holidays List";
    }
    else if (this.router.url === '/leave/leavepolicy') {
      this.heading = "Policies";
    }
    else if (this.router.url === '/team/teamattendance') {
      this.heading = "Team Attendance";
    }
    else if (this.router.url === '/team/teaminfo') {
      this.heading = "Team Info";
    }
    else if (this.router.url === '/changepassword') {
      this.heading = "Change Password"
    }
    else if (this.router.url === '/resignation/exit_interview') {
      this.heading = "Exit Interview Form"
    }
    else if (this.router.url === '/letter/offerLetterlist') {
      this.heading = "Offer Letter Approve"
    }

    else if (this.router.url === '/letter/appraisallist') {
      this.heading = "Appraisal Letter Approve"
    }
    else if (this.router.url === '/letter/relievinglist') {
      this.heading = "Relieving Letter Approval"
    }
    else if (this.router.url === '/conference/rooms') {
      this.heading = "conference Rooms"
    }
    else if (this.router.url === '/conference/booking') {
      this.heading = "conference Room Booking"
    }
    else if (this.router.url === '/conference/listing') {
      this.heading = "conference Room Booked Details"
    }
    // else if (this.router.url === '/documents/paysliplist') {
    //   this.heading = "Payslips"
    // }
    else if (this.router.url === '/ticket/dashboard') {
      this.heading = "Ticketing Dashboard"
    }
    else if (this.router.url === '/ticket/viewlist/list') {
      this.heading = "List"
    }
    else if (this.router.url === '/ticket/raiseticket') {
      this.heading = "Raise Ticket"
    }
    else if (this.router.url === '/ticket/ticketingList') {
      this.heading = "Ticketing List"
    }
    else if (this.router.url === '/tds/tdsform') {
      this.heading = "Tds Form"
    }
    else if (this.router.url === '/tds/tdslist') {
      this.heading = "Tds List"
    }
    else if (this.router.url === '/ticket/ticketingApprovalList') {
      this.heading = "Ticketing Approval List"
    }
    else if (this.router.url === '/ticket/ticketreport') {
      this.heading = "Ticketing Report"
    }
    else if (this.router.url === '/recruitment/recruitment_details') {
      this.heading = " Recruitment Details"
    }
    else if (this.router.url === '/employeedata/employeedata') {
      this.heading = "Employee Data"
    }else if (this.router.url === '/employeedetails/employeedetails') {
      this.heading = "Employee Details"
    }
    else{
      this.heading = ""
    }


  }

  //   sidebarMouseenter(e) {
  //     document.querySelectorAll('sidebar').forEach(function(el) {
  //     if (el.classList.contains("sidebar")) {
  //       this.renderer.removeClass(this.document.body, "sidebar-icon-only");
  //     }
  //     })
  // }


  //   sidebarMouseleave(e) {
  //     document.querySelectorAll('sidebar').forEach(function(el) {
  //     if (el.classList.contains("sidebar")) {    
  //       this.renderer.addClass(this.document.body, "sidebar-icon-only");
  //     }
  //   })
  // }
}


