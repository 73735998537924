import { Component, OnInit } from '@angular/core';
import { ConferenceRoomService } from '../conference-room.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-conference-room',
  templateUrl: './conference-room.component.html',
  styleUrls: ['./conference-room.component.scss'],
  providers:[DatePipe]
})
export class ConferenceRoomComponent implements OnInit {
  display_msg: boolean = false;
  display_msg1: boolean = false;
  not_valid: boolean = false;
  from_time;
  to_time;
  date;
  location;
  members;
  conference_room;
  reason;
  conferenceForm: FormGroup;
  validation_retun_flag: Boolean;
  conferenceroom = [];
  conferenceroomlist;
  locationlist;
  location_id;
  location_name;
  room_id;
  filtered_Conference_Rooms;
  conference_room_id;
  response_status;
  reason_char = 500;
  loading:boolean=false;
  responsiveOptions=[];
  isLoading: boolean = true;
  constructor(private conferenceRoomService: ConferenceRoomService,  public datepipe: DatePipe,private route: ActivatedRoute) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
   }
  
  ngOnInit(): void {
  const con_id = this.route.snapshot.paramMap.get('id');




    this.loading=true;
    if (!con_id) {
    this.filtered_Conference_Rooms = [
      { name: 'Select Conference room', id: 0 }
    ]
    this.location = [
      { name: 'Select Location', id: 0 }
    ]
  }
    if (con_id) {
      
      this.location = [
      ]
  }
    var result2 =
      {
        "params": {
          
        }
      }
    this.conferenceRoomService.conference_rooms(result2).subscribe(conferenceroomlist => {
      this.conferenceroom = conferenceroomlist['result']['data']['data'];
      this.checkDataAndStopLoading();
      if (con_id) {
        this.filtered_Conference_Rooms = [];
        this.location = [];

         var filteredRooms = this.conferenceroom.filter(room => room.conference_room_id == con_id);
         this.conference_room = filteredRooms[0].conference_room_id;
         var name = filteredRooms[0].conference_room_name;
         this.filtered_Conference_Rooms.push(
           { id: this.conference_room, name: name }
         )
         this.members = filteredRooms[0].number_of_seats;

         var location_name = filteredRooms[0].location;
         this.location_id = filteredRooms[0].location_id
         this.location.push(
           { id: this.location_id, name: location_name }
         )
      }
    });

    this.conferenceRoomService.getlocations(result2).subscribe(locationlist => {
      var location = locationlist['result']['data']['data'];
      this.checkDataAndStopLoading();
      for (let x = 0; x < location.length; x++) {
        var id = location[x].location_id;
        var name = location[x].location_name;
        this.location.push(
          { id: id, name: name }
        )
      }
      this.loading=false;
    });
   
    this.conferenceForm = this.createFormGroup();
   
  
  }
  
  createFormGroup(): FormGroup {
    return new FormGroup({
      location: new FormControl("", [Validators.required]),
      members: new FormControl("", [Validators.required]),
      date: new FormControl("", [Validators.required]),
      conference_room: new FormControl("", [Validators.required]),
      from_time: new FormControl("", [Validators.required]),
      to_time: new FormControl("", [Validators.required]),
      reason: new FormControl("", [Validators.required]),
    });
  }
  checkDataAndStopLoading() {
    if (this.conferenceroom.length > 0 && this.location.length > 0) {
      this.isLoading = false;
    }
  }
  clear() {
    this.conferenceForm.reset();
    this.conferenceForm.markAsUntouched();
    this.conferenceForm.markAsPristine();

  }
  submit() {
    this.validation_retun_flag =  this.validation_Variable();
    if(this.validation_retun_flag == true){
      var date = this.datepipe.transform(this.date, 'yyyy/MM/dd');
      var from_date = date +" "+this.datepipe.transform(this.from_time, 'HH:mm')+":00";
      var to_date = date +" "+this.datepipe.transform(this.to_time, 'HH:mm')+":00";
      var from_time = this.datepipe.transform(this.from_time, 'HH:mm')+":00";
      var to_time = this.datepipe.transform(this.to_time, 'HH:mm')+":00";
      var selected_conference_room = this.conferenceroom.filter(room => room.conference_room_id == this.conference_room);
      var number_of_seats =selected_conference_room[0].number_of_seats
      if(this.members >number_of_seats){
        this.response_status= number_of_seats+" "+"seats only available in this conference room";
            this.display_msg1=true;
            setTimeout(() => {
              this.display_msg1 = false;
            }, 3000);
      } else if(from_date > to_date){
        this.response_status= "From and to time should be valid";
            this.display_msg1=true;
            setTimeout(() => {
              this.display_msg1 = false;
            }, 3000);
      }
      else{
      var conference={
        "params":{
          "conference_room_id":this.conference_room,
          "date_from": from_date,
          "date_to": to_date,
          "date":date,
          "from_time":from_time,
          "to_time":to_time,
          "members": this.members,
          "reason": this.reason
        }
      }
  
      this.conferenceRoomService.conference_room_booking(conference).subscribe(result => {
        var check_response = result['result']
         this.response_status=result['result']['data'];
        if (check_response.status == 200) {
          this.conferenceForm.reset();
          this.conferenceForm.markAsUntouched();
          this.display_msg=true;
          setTimeout(() => {
            this.display_msg = false;
          }, 3000);
        }
        else{
          this.response_status=result['result']['data'].message;
          this.display_msg1=true;
          setTimeout(() => {
            this.display_msg1 = false;
          }, 3000);
        }
      });
      }
    }
    else {
      this.not_valid = true;
      setTimeout(() => {
        this.not_valid = false;
      }, 3000);
    }
  }
  validation_Variable(): Boolean {
    if (this.location_id == undefined || this.location_id == null) {
      return false;
    }
    if (this.members == undefined || this.members == null) {
      return false;
    }
    if (this.conference_room == undefined || this.conference_room == null) {
      return false;
    }
    if (this.date == undefined || this.date == null) {
      return false;
    }
    if (this.from_time == undefined || this.from_time == null) {
      return false;
    }
    if (this.to_time == undefined || this.to_time == null) {
      return false;
    }
    if (this.reason == undefined || this.reason == null) {
      return false;
    }
    

    return true;
  }

  
  onLocationChange(event: any) {
    this.filtered_Conference_Rooms = [{ name: 'Select Conference room', id: 0 }]
    var filteredConferenceRooms = this.conferenceroom.filter(room => room.location === this.location_id);
    for (let x = 0; x < filteredConferenceRooms.length; x++) {
      var id = filteredConferenceRooms[x].conference_room_id;
      var name = filteredConferenceRooms[x].conference_room_name;
      this.filtered_Conference_Rooms.push(
        { id: id, name: name }
      )
    }
  
  }
  reason_length(value) {
    if(value){
    var dummy = 500 - value.length;
    this.reason_char = dummy;
    }
  }

}
