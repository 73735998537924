import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";




@Injectable({
  providedIn: "root",
})

export class LeaveService {

 



  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8',
    })
    
  };
 
  private messageSource = new BehaviorSubject('');
  currentcount = this.messageSource.asObservable();
constructor(
    private http: HttpClient,
 
    private router: Router
  ) {}

  Leave_Count(count: any) {
    this.messageSource.next(count)
  }
  
  leave_balance(result2){
    return this.http.post(`${environment.url}/api/leave_balance`,result2,this.httpOptions);

  }

leave_policy(result2){
  return this.http.post(`${environment.url}/api/leave_policy/`,result2,this.httpOptions);
}
policy_list(result2){
  return this.http.post(`${environment.url}/api/policy_list/`,result2,this.httpOptions);
}
policy_acceptance(result2){
  return this.http.post(`${environment.url}/api/policy_accept/`,result2,this.httpOptions);
}
employee_policy_acceptance(result2){
  return this.http.post(`${environment.url}/api/employee_policy_acceptance/`,result2,this.httpOptions);
}
leave_approve(result2){
  return this.http.post(`${environment.url}/api/approve_leave_lists`,result2,this.httpOptions);
}
leave_lists(result2){
  return this.http.post(`${environment.url}/api/leave_lists`,result2,this.httpOptions);
}
leave_apply(leave){
  return this.http.post(`${environment.url}/api/leave_apply`,leave,this.httpOptions);
}
leave_delete(leave_del){
   return this.http.post(`${environment.url}/api/delete_list`,leave_del,this.httpOptions);
}
leave_action(action){
  return this.http.post(`${environment.url}/api/leave_action`,action,this.httpOptions);
}
}
