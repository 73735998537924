import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "src/app/login/login.service";
import "animate.css";
import { EventEmitterService } from "src/app/services/eventemitter.service";
import { LeaveService } from "src/app/leave/leave.service";
import { OndutyService } from "src/app/onduty/onduty.service";
import { interval, Subscription } from 'rxjs';
import { PermissionService } from "src/app/permission/permission.service";
import { CompoffService } from "src/app/compoff/compoff.service";
import { WfhService } from "src/app/wfh/wfh.service";
import { ResignationService } from "src/app/resignation/resignation.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  counts;
  total_counts;
  activeMenu;
  selectedItem;
  submenuParentActive;
  currentElement;
  leave;
  wfh;
  perm;
  compoff;
  on_duty;
  resignationEmp;
  ticket;
  noDueList;
  overall_approve;
  on_duty_approve;
  offer_letter_approve;
  ticketing_dashboard;
  relieve_approve;
  appraisal_letter_approve;
  ticket_approve;
  ticket_report;
  recruitment: any;
  leave_count: any;
  onduty_count: any;
  mySubscription: Subscription;
  tds: any;
  loaderflag: boolean = false;
  permission_count: any;
  wfh_count: any;
  compoff_count: any;
  resi_count: any;
  nodue: any;
  dashboard_show: any;
  emp_data_user: any;
  emp_data_manager: any;
  recruitment_show: any;
  backendUrl=environment.url
  constructor(public router: Router,
    private loginService: LoginService,
    private eventEmitterService: EventEmitterService,
    private leaveService: LeaveService,
    private ondutySrvice: OndutyService,
    private perService: PermissionService,
    private compoffservice: CompoffService,
    private wfhservice: WfhService,
    private resignationService: ResignationService) { }
  ngOnInit() {
    this.leaveService.currentcount.subscribe(msg => { this.leave_count = msg });
    this.ondutySrvice.currentcount.subscribe(msg => { this.onduty_count = msg });
    this.perService.currentcount.subscribe(msg => { this.permission_count = msg });
    this.compoffservice.currentcount.subscribe(msg => { this.compoff_count = msg });
    this.wfhservice.currentcount.subscribe(msg => { this.wfh_count = msg });
    this.resignationService.currentcount.subscribe(msg => { this.resi_count = msg });
    // this.total_counts = this.leave_count + this.wfh_count + this.permission_count + this.compoff_count + this.onduty_count;
    var result2 =
    {
    }
    this.loginService.maintanenceTime(result2).subscribe(counts => {
      this.counts = counts['result']['data']['approval_count'];
      this.ticket = this.counts.ticketing;
      // this.total_counts = this.leave + this.wfh + this.perm + this.compoff + this.on_duty;
    });
    this.loginService.approveAuthority(result2).subscribe(approvelist => {
      this.overall_approve = approvelist['result']['data']['approve_authority'];
      // localStorage.setItem("approve_authority", final_approve);
      this.on_duty_approve = approvelist['result']['data']['on_duty'];
      // localStorage.setItem("on_duty", on_duty);
      this.offer_letter_approve = approvelist['result']['data']['offer_letter'];
      // localStorage.setItem("offer_letter",offer_letter);
      this.relieve_approve = approvelist['result']['data']['relieve_letter'];
      // localStorage.setItem("relieve_letter",relieve);
      this.appraisal_letter_approve = approvelist['result']['data']['appraisal_approve'];
      this.ticket_approve = approvelist['result']['data']['is_ticketing_approval'];
      this.ticket_report = approvelist['result']['data']['is_ticketing_report'];
      this.recruitment = approvelist['result']['data']['recruitment_show'];
      this.tds = approvelist['result']['data'].tds_show;
      this.nodue = approvelist['result']['data']['due_list_menu'];
      this.dashboard_show = approvelist['result']['data']['dashboard_show'];
      this.emp_data_user = approvelist['result']['data']['is_employee_data_user'];
      this.emp_data_manager = approvelist['result']['data']['is_employee_data_manager'];
      this.ticketing_dashboard = approvelist['result']['data']['ticketing_dashboard'];
      // this.recruitment_show = approvelist['result']['data']['recruitment_show'];
    });
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
    // Navbar Profile button
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        sidebarComponentFunction.subscribe((data) => {
          this.clickedMenu(data.event, data.item);
        });
    }
  }
  public parentId = "";
  clickedMenu(event, item) {
    this.selectedItem = item
    this.submenuParentActive = ''
    const listItems = document.querySelectorAll('li div');
    for (let i = 0; i <= listItems.length - 1; i++) {
      const a = listItems[i].classList
      const b = Array.from(a)
      if (b.includes('show')) {
        listItems[i].classList.remove('show')
      }
    }
  }
  clickedMenu1(e, item) {
    this.submenuParentActive = item
    this.selectedItem = ''
    this.currentElement = e.target.parentNode.childNodes[1].id
    const listItems = document.querySelectorAll('li div');
    for (let i = 0; i <= listItems.length - 1; i++) {
      const a = listItems[i].classList
      const b = Array.from(a)
      if (listItems[i].id == item) {
        if (b.includes('collapse')) {
          listItems[i].classList.add('show')
        }
        if (b.includes('show')) {
          listItems[i].classList.remove('show')
        }
      } else {
        if (b.includes('show')) {
          listItems[i].classList.remove('show')
        }
      }
    }
  }
  getApprove() {
    // if (localStorage.getItem("approve_authority") == 'true') {
    //   return true
    // } else {
    //   return false
    // }
  }
  ticketApprove() {
    // if (localStorage.getItem("ticket") == 'true' || localStorage.getItem("approve_authority") == 'true') {
    //   return true
    // } else {
    //   return false
    // }
  }
  onduty() {
    // if (localStorage.getItem("on_duty") == 'true') {
    //   return true
    // } else {
    //   return false
    // }
  }
  offer_letter() {
    // if (localStorage.getItem("offer_letter") == 'true') {
    //   return true
    // } else {
    //   return false
    // }
  }
  relieve_letter() {
    //   if (localStorage.getItem("relieve_letter") == 'true') {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  }
  clickedMenuAdp(event: Event, menu: string) {
      if (menu === 'Payroll-portal') {
        const externalUrl = 'https://launcher.myapps.microsoft.com/api/signin/977f01be-c5d0-4179-a7e1-84820a23c762?tenantId=094d25ba-3306-4bc2-b789-6eb55f87b309';
        window.open(externalUrl, '_blank'); 
      }

  }
}
