import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";



@Injectable({
  providedIn: "root",
})

export class ChangepwdService {

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8',
    })
    
  };

constructor(
    private http: HttpClient,
 
    private router: Router
  ) {}

  
  changepwd(result2){
    return this.http.post(`${environment.url}/api/change_password`,result2,this.httpOptions);

  }
 


}
