import { Component, OnInit } from '@angular/core';
import { RaiseticketService } from '../raiseticket/raiseticket.service';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
interface Department {
  name: string;
  department_id: number;
  count: number;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  ticket_list;
  departmentCounts: Department[] = [];
  today_ticket_counts =[];
  closed_ticket_counts =[];
  tat_ticket_counts=[];

  constructor(
    private raiseticket: RaiseticketService,private router: Router) { }
   
  ngOnInit(): void {
    
    var result = {
      "params": {

      }
    }
    
    this.raiseticket.dashboard_count(result).subscribe(ticket => {
     this.ticket_list = ticket['result']['data'];
    var departmentCounts =ticket['result']['data'].department_counts;
    this.departmentCounts = Object.values(departmentCounts);
    var todayCounts =ticket['result']['data'].today_created;
    this.today_ticket_counts = Object.values(todayCounts);

    var closedCounts =ticket['result']['data'].closed;
    this.closed_ticket_counts = Object.values(closedCounts);


    var tatCounts =ticket['result']['data'].tat;
    this.tat_ticket_counts = Object.values(tatCounts);



    });
   

}

getCountByName(departmentName: string): number | undefined {
  const department = this.departmentCounts.find(dept => dept.name === departmentName);
  return department ? department.count : 0;
}



gettodayCountByName(departmentName: string): number | undefined {
  const department = this.today_ticket_counts.find(dept => dept.name === departmentName);
  return department ? department.count : 0;
}


getclosedCountByName(departmentName: string): number | undefined {
  const department = this.closed_ticket_counts.find(dept => dept.name === departmentName);
  return department ? department.count : 0;
}


gettatCountByName(departmentName: string): number | undefined {
  const department = this.tat_ticket_counts.find(dept => dept.name === departmentName);
  return department ? department.count : 0;
}
 
redirectToDestination(department: string = '', status: string = '',date :string = '') {
  // const targetURL = '/ticket/list'; 
  // console.log(targetURL)
  // this.router.navigate([targetURL, id]);
  

  this.router.navigate(['/ticket/viewlist/list'], { queryParams: { department: department, status: status, date: date } });
}
}