import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { CookieService } from "ngx-cookie-service";
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isAuthenticated$;
  isEmployee$;
  constructor(private router:Router,
    private loginService:LoginService,
    private cookie: CookieService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return this.loginService.isAuthenticated$;
    const IsCookieExists: boolean = this.cookie.check('session_id');
    // const IsCookieExists: boolean = true;
    if(IsCookieExists){
      return this.isAuthenticated$=true;
    }else{
        this.router.navigate(['/login']);
     return this.isAuthenticated$=false;
    }
  }
 

  
}
